<template>
  <div class="login_out_wrap">
    <!-- <div class="header">
      <div class="left">
      </div>
      <div class="right">
      </div>
    </div> -->
    <div class="content">
      <div class="inner_content">
          <router-view></router-view>
      </div>
    </div>
    <div class="footer">
      <div>Copyright © 2020-2025  苏ICP备19074164号-1 </div>
    </div>
  </div>
</template>
<script>
import {login} from '@/api/login.js'
export default {
  name:'LoginOut',
  data(){
    return{
    }
  },
  mounted(){

  },
  methods:{
  }
}
</script>

<style scoped lang='scss'>
.login_out_wrap{
  height: 100%;
  background-color: #25418F;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    background-image: url(../../assets/image/login/bg_img1.png);
    background-size: 100% 100%;
    .inner_content{
      position: relative;
      width: 400px;
      height: 100%;
      padding: 0 50px;
      background-color: white;
      align-content: center;
    }
  }
  .footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 12px;
    opacity: .5;
  }
  .title_wrap{
    width: 616px;
    height:57px;
    margin-left: 169px;
    .up_text{
      color: white;
      font-size: 56px;
    }
    .down_text{
      width: 361px;
      height: 23px;
      font-size: 22px;
      color:#AEACB1;
      margin-top:20px
    }
  }
}
</style>
