import request from '@/api/request.js'

//登录
export function loginFn (data){
  return request({
    url:'/village/auth/login',
    method:'POST',
    data:data
  })
}
//修改密码
export function changePwdFn (data){
  return request({
    url:'/village/my/modify_password',
    method:'POST',
    data:data
  })
}